#content-web-site-wrapper {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 24px 24px;
    max-width: 1224px;

    &.left-content {
        .yellow-div {
            border-radius: 16px 16px 0 0;
        }

        .toggle-image {
            border-radius: 0 0 16px 16px;
        }

        .chat-background-image {
            border-radius: 0 0 16px 16px;
        }

        .purple-div {
            border-radius: 0 0 16px 16px;
        }
    }

    &.right-content {
        .yellow-div {
            border-radius: 0 0 16px 16px;
        }

        .toggle-image {
            border-radius: 16px 16px 0 0;
        }

        .chat-background-image {
            border-radius: 16px 16px 0 0;
        }

        .purple-div {
            border-radius: 0 0 16px 16px;
        }
    }

    .yellow-div {
        position: relative;
        background-color: var(--dawn);
        min-height: 540px;
        flex: 1;

        .text-container {
            position: relative;
            z-index: 3;
            height: auto;

            .feature-pill {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }

            .divider {
                width: 100%;
                height: 1px;
                background-color: var(--gray-dark);
            }

            .list-item-container {
                height: auto;
            }

            .title-icon {
                img {
                    width: 40px;
                    height: 40px;
                }
            }

            span {
                color: var(--pink-hot);
                font-weight: 400;
            }
        }
    }

    .purple-div {
        position: relative;
        min-height: 540px;
        flex: 1;
    }

    .yellow-div__list {
        padding-right: 0;
        padding-left: 0;
    }

    @media only screen and (min-width: 48em) {
        flex-direction: row;

        .yellow-div {
            padding: 40px;
        }

        &.left-content {
            .yellow-div {
                border-radius: 16px 0 0 16px;
            }

            .toggle-image {
                border-radius: 0 16px 16px 0;
            }

            .chat-background-image {
                border-radius: 0 16px 16px 0;
            }

            .purple-div {
                border-radius: 0 0 16px 16px;
            }
        }

        &.right-content {
            .yellow-div {
                border-radius: 0 16px 16px 0;
            }

            .toggle-image {
                border-radius: 16px 0 0 16px;
            }

            .purple-div {
                border-radius: 0 0 16px 16px;
            }
        }
    }

    @media only screen and (min-width: 80em) {
        padding: 0;
    }
}
