.chat-message__container {
  width: 100%;
  display: flex;
}

.chat-message__container.left {
  justify-content: flex-start;
}

.chat-message__container.right {
  justify-content: flex-end;
}

.chat-message {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 12px 0 12px 16px;
  box-sizing: border-box;
}

.left .chat-message {
  border-radius: 0px 16px 16px 16px;
  background-color: rgba(35, 35, 35, 0.55);
  align-self: flex-start;
}

.right .chat-message {
  border-radius: 16px 0px 16px 16px;
  background-color: rgba(0, 0, 0, 0.45);
  align-self: flex-end;
}

.chat-message__content {
  color: var(--chalk);
  font-weight: 400;
  font-size: 14px;
}

.chat-time {
  margin-left: 6px;
  color: #999;
  font-weight: 400;
  font-size: 11px;
  align-self: flex-end;
}

.translate-disclaimer {
  margin-top: 4px;
  color: rgba(204, 204, 204, 1);
  font-size: 12px;
  font-style: italic;
}

.translate-disclaimer-right {
  margin-top: 0;
  margin-bottom: 4px;
  text-align: right;
}

.flag {
  width: 24px;
}

.flag-image-container {
  display: flex;
  justify-content: flex-end;
}

.flag-right {
  width: 24px;
  align-self: flex-end;
}
