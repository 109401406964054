#background-component-lp {
    position: relative;
    z-index: 2;
    border-radius: 8px;
    background-color: var(--midnight);
    padding: 40px;

    #header-default {
        gap: 20px;
    }

    @media only screen and (width >= 30em) {
        // padding: 80px 40px;
    }

    @media only screen and (width >= 80em) {
        max-width: 1224px;
    }
}
