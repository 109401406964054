#category-default {
  position: relative;
  border-radius: 24px;
  width: auto;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(85, 85, 85, 0.25);
  color: var(--faded);
  font-weight: 400;

  & p {
    &.translatableText {
      font-size: 0.75em;
    }
  }
}
