#footer-landing-page {
  margin-bottom: 80px;
  width: 100%;
  font-family: "Roboto Regular";
  font-size: 24px;
  text-align: center;
  background: var(--black);

  .images-links-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;

    .logos-container {
      display: flex;
      justify-content: center;
      gap: 24px;

      [alt="roses logo"] {
        width: 24px;
        height: 24px;
      }
    }
  }

  .separator-footer {
    margin: 20px 40px;
    border-top: 1px solid var(--gray-dark);
  }

  .links-terms {
    padding: 10px 90px 0;
    font-size: 0.75rem;
    text-align: center;

    span {
      padding: 4px;
      color: var(--gray);
    }

    p {
      color: var(--gray-light);
      font-size: 0.75rem;
    }
  }

  @media only screen and (width >= 30em) {
    .separator {
      margin: 40px;
    }
  }

  @media only screen and (width >= 80em) {
    margin: 80px 0px;

    .logos-container {
      [alt="roses logo"] {
        width: 30px;
        height: 30px;
      }
    }
  }
}
