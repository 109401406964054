#image-toggle-container {
    position: relative;
    height: 540px;
    // max-width: 600px;

    .image_toggle_action {
        position: absolute;
        z-index: 10;
        bottom: 40px;
        left: 50%;
        border-radius: 16px;
        width: calc(100% - 32px);
        display: flex;
        align-items: center;
        background-color: var(--midnight);
        padding: 16px 24px;
        transform: translateX(-50%);
        gap: 16px;

        label {
            display: flex;
            flex-direction: column;
        }

        .toggle-container {
            margin-left: auto;
        }
    }

    .toggle-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        //transition: opacity 0.5s ease-in-out;
        object-fit: cover;
    }

    .hidden {
        opacity: 0;
        pointer-events: none;
    }

    .visible {
        opacity: 1;
        pointer-events: all;
    }

    .list-item-container {
        position: absolute;
        z-index: 1;
        bottom: 64px;
        left: 50%;
        border-radius: 16px;
        width: calc(100% - 16px);
        height: 72px;
        background-color: var(--midnight);
        transform: translateX(-50%);
    }

    .shield-modal {
        position: absolute;
        z-index: 2;
        top: 40%;
        left: 50%;
        border-radius: 8px;
        width: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: var(--black);
        transform: translate(-50%, -50%);
        text-align: center;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
    }

    .shield-modal p {
        color: var(--gray-light);
    }

    .shield-modal img {
        width: 24px;
        height: 24px;
    }

    .shield-modal .title-modal {
        color: var(--white);
        font-size: 14px;
        text-transform: uppercase;
    }

    .shield-modal.show {
        opacity: 1;
    }

    .profile-link {
        color: var(--pink-hot);
    }

    @media only screen and (width >= 30em) {
        .list-item-container {
            max-width: 480px;
        }
    }

    @media only screen and (width >= 80em) {
        .image_toggle_action {
            width: calc(100% - 80px);
        }

        .list-item-container {
            width: calc(100% - 150px);
        }
    }
}
