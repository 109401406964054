#image-chat-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .chat-background-image {
        width: 100%;
        height: 100%;
        min-height: 500px;
        object-fit: cover;
    }

    .text-container {
        position: absolute;
        right: 40px;
        bottom: 40px;
        display: flex;
        align-items: center;

        p {
            color: rgba(255, 255, 255, 0.5);
            font-size: 14px;
        }

        .icon {
            width: 30px;
            height: 30px;
        }
    }
    .chat-message-container-left,

    .chat-message-container-right {
        position: absolute;
        width: 240px;
        opacity: 0;
        transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
        transform: translateY(50px);
    }

    .chat-message-container-left {
        top: 25%;
        left: 40px;
    }

    .chat-message-container-right {
        right: 40px;
        bottom: 25%;
    }

    .chat-message-container-left.active {
        opacity: 1;
        transform: translateY(0);
    }

    .chat-message-container-right.active {
        opacity: 1;
        transform: translateY(0);
    }

    @media only screen and (max-width: 375px) {
        .chat-message-container-left,

        .chat-message-container-right {
            scale: 0.7;
        }
    }

    @media only screen and (min-width: 30em) {
        .image-chat-container {
            .chat-message-container-left,

            .chat-message-container-right {
                scale: 1;
            }

            .chat-message-container-left {
                top: 40%;
                left: 10%;
            }

            .chat-message-container-right {
                right: 10%;
                bottom: 25%;
            }
        }
    }

    @media only screen and (min-width: 80em) {
        }
    }
