#modal-pwa {
    position: fixed;
    z-index: 13;
    top: 50%;
    left: 50%;
    border: none;
    border-radius: 24px;
    width: calc(100% - 48px);
    height: auto;
    background-color: var(--midnight);
    max-width:460px;
    transform: translate(-50%, -50%);

    .modal-content{
        display: flex;
        flex-direction: column;
    }

    .logo-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;

        img {
            border-radius: 16px;
            width: 80px;
            height: 80px;
        }
    }

    .list-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
            &.save-icon {
                width: 24px;
                height: 24px;
                padding: 3px;
            }

            &.sphere-icon {
                margin-top: -12px;
            }
        }
    }

    #button {
        margin: 0;
    }
}
