.account-login-page {
    .login-container {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .oauth-buttons {
            display: flex;
            flex-direction: row;
            gap: 8px;
            cursor: pointer;
        }
    }

    .buttons-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
}
