#icon-and-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    max-width: 600px;

    img {
        width: 24px;
        height: 24px;
        background-color: transparent;
    }

    .text-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: transparent;
        text-align: center;

        .title-text {
            background-color: transparent;
        }
    }

    .value-text {
        background-color: transparent;
        color: var(--white);
        text-transform: uppercase;
    }

    @media only screen and (width >= 80em) {
        margin: 0 auto;
    }
}
