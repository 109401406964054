.list-item-container {
    position: relative;
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    padding: 12px 16px;
    .checkbox-left,

    .checkbox-right {
        position: relative;
        margin-bottom: 12px;
        display: block;
        padding-left: 35px;
        font-size: 22px;
        cursor: pointer;
        user-select: none;

        input {
            position: absolute;
            width: 0;
            height: 0;
            opacity: 0;
            cursor: pointer;
        }

        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 6px;
            width: 22px;
            height: 22px;
            background-color: var(--charcoal);
            top: -5px;

            &:after {
                position: absolute;
                top: 3px;
                left: 7px;
                border: solid var(--charcoal);
                border-width: 0 3px 3px 0;
                width: 5px;
                height: 10px;
                display: none;
                content: "";
                transform: rotate(45deg);
            }
        }

        input:checked ~ .checkmark {
            position: absolute;
            top: -3px;
            border-radius: 6px;
            width: 22px;
            height: 22px;
            background-color: var(--pink-hot);

            &:after {
                display: block;
            }
        }
    }

    .radio-container {
        display: flex;

        .list-radio {
            accent-color: var(--pink);
        }

        .list-radio:checked + label {
            color: var(--white);
        }

        .list-radio:after {
            position: relative;
            top: -4px;
            left: -1px;
            border: 4px solid var(--charcoal);
            border-radius: 15px;
            width: 16px;
            height: 16px;
            display: inline-block;
            background-color: var(--charcoal);
            content: "";
            visibility: visible;
        }

        .list-radio:checked:after {
            position: relative;
            top: -4px;
            left: -1px;
            border: 4px solid var(--charcoal);
            border-radius: 15px;
            width: 16px;
            height: 16px;
            display: inline-block;
            background-color: var(--pink);
            content: "";
            visibility: visible;
        }
    }

    .sphere-icon {
        bottom: 0;
        width: 24px;
        height: 24px;
        align-self: baseline;
    }
    .icon-left,
    .icon-right,

    .icon-main {
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .icon-right {
        padding: 7px;
    }

    .icon-left {
        }

        .icon-left-border {
            border: 1px solid var(--charcoal);
            border-radius: 50%;
            width: 40px;
            height: 40px;

            img {
                border-radius: 50%;
                width: 18px;
                height: 18px;
            }
        }

        .initials-contact {
            border: 1px solid var(--charcoal);
            border-radius: 20px;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;

            p {
                color: var(--white);
                font-family: "Montserrat Variable";
                font-size: 12px;
                text-transform: uppercase;
            }
        }

        .text-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            flex-grow: 1;
        }

        .value {
            color: var(--gray-light);
            font-family: "Roboto Regular";
            font-size: 14px;
        }

        .info {
            color: #999;
        }

        .category {
            color: #999;
        }

        .separator {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: var(--charcoal);
        }
    }

    .subtext-info {
        max-width: 90%;
    }

    .bottom-container {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        flex-direction: column;
        padding: 20px;
    }
