html,

html * {
    margin: 0;
    padding: 0;
    font-family: "Roboto", "Helvetica Neue", "Arial", sans-serif;
    box-sizing: border-box;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
        display: none !important;
    }
}

body {
    position: relative;
    font-family: "Roboto Regular";
    font-size: 16px;
    overflow-x: hidden;
    overscroll-behavior: none;
    background: var(--default-color-background);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.4;
    h1,
    h2,
    h3,
    h4,
    h5,

    h6 {
        margin: 16px 0;
        color: var(--default-color-header);
        font-family: "Montserrat Variable";
        font-weight: 400;
        text-transform: none;

        strong {
            font-weight: 400;
        }

        span {
            color: var(--pink-hot);
            font-size: 1rem;
            //display: block;
        }

        &.no-margin-top {
            margin: 0 0 16px 0;
        }
    }

    h1 {
        font-size: 2.5rem;
        //40px
        line-height: 1.2;
    }

    h2 {
        font-size: 2rem;
        //32px
        line-height: 1.2;
    }

    h3 {
        font-size: 1.75rem;
        //28px
        line-height: 1.4;

        &.header {
            font-size: 1.5rem;
            //24px
        }
    }

    h4 {
        font-size: 1.25rem;
        //20px
        line-height: 1.4;
    }

    h5 {
        font-size: 1rem;
        //16px
        line-height: 1.4;
    }

    h6 {
        font-size: 0.875rem;
        //14px
        line-height: 1.4;
    }

    hr {
        margin: 40px 0;
        border: none;
        outline: none;
        border-bottom: 1px solid var(--gray-dark);

        &.white {
            border-bottom: 1px solid var(--white);
        }

        &.pink {
            border-bottom: 1px solid var(--pink-hot);
        }
    }

    span {
        line-height: 1.4;

        &.highlight {
            margin-left: 4px;
            color: var(--pink-hot);
        }

        &.disclaimer {
            margin-top: 8px;
            color: var(--gray);
            font-size: 0.75rem;
            font-style: italic;

            &.right {
                justify-self: flex-end;
            }

            &.left {
                justify-self: flex-start;
            }
        }

        &.time {
            color: var(--gray);
            font-size: 0.75rem;
        }
    }

    p {
        margin: 8px 0;
        color: var(--default-color-text);
        font-family: "Roboto Regular";
        font-size: 1.125rem;
        //18px
        line-height: 1.4;

        &.small-p {
            font-size: 0.875rem;
            //14px
        }

        &.white-p {
            color: var(--white);
        }

        &.description__lp {
            font-size: 1rem;
        }

        &.profile-info {
            color: var(--white);
            font-family: "Montserrat Variable";
            font-weight: 600;
            font-size: 1.25rem;

            &.small {
                font-size: 0.875rem;
                //14px
            }

            span {
                margin-left: 4px;
            }
        }

        &.margin-bottom {
            margin-bottom: 16px;
        }

        &.text-gray {
            color: var(--gray);
        }

        &.message {
            font-size: 0.875rem;
            word-break: break-word;
        }

        span {
            &.text-highlight {
                color: var(--gray-light);
            }

            &.text-highlight--white {
                color: var(--white);
            }

            &.photo-highlight {
                color: var(--pink-hot);
            }

            &.referral-code {
                padding-left: 4px;
                color: var(--white);
            }
        }

        &.counter {
            margin: 0;
            color: var(--ash);
            font-size: 0.875rem;
            //14px
        }

        &.description {
            margin-top: 4px;
            color: var(--gray-light);
            font-size: 0.875rem;
        }

        &.caption {
            position: relative;
            margin: 24px auto;
            display: block;
            padding: 0 0 0 32px;
            color: var(--gray);
            font-size: 0.75rem;
            //12px
            text-align: left;

            &.no-padding {
                padding-left: 0;
            }

            &.small-padding {
                padding-left: 16px;
            }

            &.tiny-margin {
                margin: 12px 0;
            }

            .icon {
                position: absolute;
                top: 0;
                left: 0;

                &:before {
                    color: var(--gray);
                }
            }
        }

        &.description {
            margin-top: 4px;
            color: var(--gray-light);
            font-size: 0.75rem;
            //12px
        }

        &.disclaimer {
            border-radius: 8px;
            display: inline-block;
            padding: 8px 12px;
            color: var(--gray);
            font-size: 0.75em;
            background: rgba(0, 0, 0, 0.5);
        }

        &.essential-info {
            color: var(--gray-light);
            font-size: 0.75rem;
            //12px
        }
    }

    .icon {
        pointer-events: none;
        &:before,

        &:after {
            color: var(--pink-hot);
        }

        &.icon-round {
            border: 1px solid var(--charcoal);
            border-radius: 50%;
            width: 40px;
            height: 40px;
            display: grid;
            flex-shrink: 0;
            place-items: center;
        }

        &.icon-sm {
            font-size: 16px;
        }

        &.icon-md {
            font-size: 24px;
        }

        &.icon-lg {
            font-size: 32px;
        }

        &.icon-xlg {
            font-size: 40px;
        }

        &.icon-match {
            &:before {
                color: var(--default-color-success);
            }
        }

        &.icon-gray {
            &:before {
                color: var(--gray-mid);
            }
        }

        &.icon-gray-light {
            &:before {
                color: var(--gray-light);
            }
        }

        &.icon-blue {
            &:before {
                color: var(--blue);
            }
        }

        &.icon-white {
            &:before {
                color: var(--white);
            }
        }

        &.icon-remove {
            &:before {
                color: var(--peach);
            }
        }
    }

    label {
        color: var(--white);
        font-family: "Roboto Regular";
        font-size: 0.875rem;
        line-height: 1.4;

        &.input {
            font-size: 0.75rem;
        }

        &.essential-label {
            font-size: 0.75rem;
            text-transform: uppercase;
        }

        &.label__lp {
            font-size: 1rem;
        }

        span {
            color: var(--gray-light);
            font-size: 0.75rem;
        }
    }

    sup {
        margin: 0 4px;
        font-weight: normal;
        font-size: 0.3em;
        vertical-align: super;
    }

    b {
        font-weight: 600;
    }
    a,

    .anchor-link {
        color: var(--pink-hot);
        font-family: "Roboto Regular";
        font-size: 0.75rem;
        // 12px
        text-decoration: none;
        cursor: pointer;
    }

    button {
        font-size: 0.75em;
    }

    select {
        margin: 8px 4px;
        border: 1px solid var(--midnight);
        border-radius: 24px;
        padding: 12px 24px 12px 24px;
        color: var(--white);
        font-size: 0.86rem;
        line-height: 1.4;
        min-height: 48px;
        outline: none;
        background: var(--charcoal) url("../assets/icons/chevron-bottom.svg")
        no-repeat 90% 50%;
        appearance: none;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
        &:focus,

        &:focus-visible {
            //border: 1px solid var(--ash);
            box-shadow: none;
            outline: none;
        }

        &#language-code {
            width: 180px;
        }
    }

    ol {
        padding-left: 32px;

        li {
            padding: 4px 0;
            color: var(--white);
            list-style-type: decimal-leading-zero;
        }
    }

    ul {
        padding: 16px 0;

        li {
            color: var(--default-color-text);
        }

        &.list-roman {
            padding-left: 24px;

            li {
                list-style-type: lower-roman;
                line-height: 1.5;
            }
        }
    }

    .modal-overlay {
        position: fixed;
        z-index: 12;
        top: 0;
        left: 0;
        width: 100dvw;
        height: 100dvh;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.8);
    }

    .modal-content {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    input:focus,
    textarea:focus,

    select:focus {
        border: 1px solid var(--white) !important;
        outline: none !important;
        box-shadow: none !important;
    }

    .separator {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: var(--charcoal);
    }
}
