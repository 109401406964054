.toastContainer {
    position: fixed;
    z-index: 100;
    left: 50%;
    border: 1px solid var(--charcoal);
    border-radius: 16px;
    width: 342px;
    height: 94px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--midnight);
    padding-right: 12px;
    transform: translateX(-50%);
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    opacity: 0;
    transition: opacity 0.5s ease-in-out, top 0.5s ease-in-out;
}

.iconContainer {
    position: absolute;
    top: 24px;
    left: 24px;
    border: 1px solid var(--gray-dark);
    border-radius: 20px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.textContainer {
    margin-left: 80px;
    background-color: var(--midnight);
}

.textPrimary {
    background-color: var(--midnight);
    color: var(--white);
    font-size: 16px;
    margin-bottom: 0;
}

.textSecondary {
    background-color: var(--midnight);
    color: var(--gray-light);
    font-weight: 400;
    font-size: 14px;
    margin-top: 0;
}

.toastContainer.show {
    top: 24px;
    opacity: 1;
}

.toastContainer.hide {
    z-index: 3;
    top: -100px;
    opacity: 0;
}
