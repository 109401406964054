.translatableText {
  font-family: "Roboto Regular", sans-serif;
  font-weight: 400;
  font-size: 18px;

  &--alt {
    font-family: "Montserrat Variable", sans-serif;
    font-weight: 600;
    font-size: 10px;
  }
}
